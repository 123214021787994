import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  align-self: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 250px;

  text-align: center;

  gap: ${(p) => p.theme.spacing.small};

  color: ${(p) => p.theme.colors.textSecondary};
`;

export const WrapperIcon = styled.div`
  color: ${(p) => p.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${(p) => p.theme.spacing.xLarge};
  padding: ${(p) => p.theme.spacing.xxLarge};
  margin-bottom: ${(p) => p.theme.spacing.medium};

  border: 1px dashed ${(p) => p.theme.colors.primary};
  border-radius: 100%;

  & > svg {
    stroke-width: 1.5px;
  }
`;

export const Title = styled.h2`
  font-size: ${(p) => p.theme.font.large};
`;

export const Description = styled.p`
  font-size: ${(p) => p.theme.font.small};
  line-height: ${(p) => p.theme.spacing.medium};
`;
