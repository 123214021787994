import styled, { keyframes } from 'styled-components';

const slideToLeft = keyframes`
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
`;

const slideToRight = keyframes`
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.25);

  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const Drawer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  max-width: 420px;

  background-color: ${(p) => p.theme.colors.background};

  display: flex;
  flex-direction: column;

  animation: ${slideToLeft} 0.3s ease-in-out;

  overflow: auto;

  width: 100%;
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 1000;

  &.isHidden {
    ${Drawer} {
      animation: ${slideToRight} 0.3s ease-in-out;
      transform: translateX(100%);
    }

    ${Overlay} {
      opacity: 0;
      animation: ${fadeOut} 0.3s ease-in-out;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(p) => p.theme.colors.background};
  padding: ${(p) => p.theme.spacing.large} ${(p) => p.theme.spacing.large}
    ${(p) => p.theme.spacing.medium};

  border-bottom: 1px solid ${(p) => p.theme.colors.border};

  position: sticky;
  top: 0;

  @media (max-width: 768px) {
    font-size: ${(p) => p.theme.font.large};

    padding: ${(p) => p.theme.spacing.medium};
  }
`;

export const Title = styled.h1`
  color: ${(p) => p.theme.colors.textPrimary};
  font-size: ${(p) => p.theme.font.xLarge};
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: ${(p) => p.theme.font.large};
  }
`;

export const WrapperDelivery = styled.div`
  padding: ${(p) => p.theme.spacing.medium} ${(p) => p.theme.spacing.large};

  border-top: 1px solid ${(p) => p.theme.colors.border};
`;

export const ButtonClose = styled.button`
  cursor: pointer;
  color: ${(p) => p.theme.colors.textPrimary};
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(p) => p.theme.font.xLarge};

  & svg {
    stroke-width: 3;
  }
`;

export const WrapperFooter = styled.div`
  display: flex;

  gap: ${(p) => p.theme.spacing.small};
  padding: ${(p) => p.theme.spacing.medium} ${(p) => p.theme.spacing.large}
    ${(p) => p.theme.spacing.large};

  border-top: 1px solid ${(p) => p.theme.colors.border};

  & > button {
    flex: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: ${(p) => p.theme.spacing.medium};

    & > button {
      font-size: ${(p) => p.theme.font.xSmall};
    }
  }
`;
