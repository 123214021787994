import { FC } from 'react';
import { FiShoppingBag } from 'react-icons/fi';

import { Wrapper, WrapperIcon, Title, Description } from './styles';

export const EmptyBag: FC = () => {
  return (
    <Wrapper>
      <WrapperIcon>
        <FiShoppingBag />
      </WrapperIcon>
      <Title>Sua sacola está vazia.</Title>
      <Description>Volte para o catálogo para adicionar produtos.</Description>
    </Wrapper>
  );
};
