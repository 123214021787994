import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  overflow: auto;
  flex-direction: column;
  padding: ${(p) => p.theme.spacing.nano} 0;

  font-size: ${(p) => p.theme.font.small};

  @media (max-width: 768px) {
    font-size: ${(p) => p.theme.font.xSmall};
  }
`;

export const WrapperImage = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border: 1px solid ${(p) => p.theme.colors.border};
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.backgroundHover};
  color: ${(p) => p.theme.colors.primary};

  & svg {
    width: 50%;
    height: 50%;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Name = styled.div`
  max-width: 100%;

  flex: 1;

  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.quark};

  > span {
    font-weight: 600;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  > small {
    font-weight: 400;
    color: ${(p) => p.theme.colors.textSecondary};
  }
`;

export const WrapperPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${(p) => p.theme.spacing.nano};
  flex-shrink: 0;
`;

export const Price = styled.div`
  font-weight: 600;
`;

export const UnityPrice = styled.div`
  font-size: ${(p) => p.theme.font.xSmall};
  font-style: italic;
`;

export const WrapperAction = styled.div`
  flex-shrink: 0;
  color: ${(p) => p.theme.colors.primary};
  display: flex;
  gap: ${(p) => p.theme.spacing.small};
`;

export const WrapperProduct = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.small};
  padding: ${(p) => p.theme.spacing.medium} ${(p) => p.theme.spacing.large};

  &:not(:first-child) {
    border-top: 1px solid ${(p) => p.theme.colors.border};
  }

  cursor: pointer;

  @media (max-width: 768px) {
    padding: ${(p) => p.theme.spacing.small} 0;
    padding: ${(p) => p.theme.spacing.small} ${(p) => p.theme.spacing.medium};
  }
`;

export const ProductInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing.medium};

  transition: opacity 0.3s;

  &:hover {
    opacity: 0.65;
  }
`;

export const RemoveProductButton = styled.button`
  border: 1px solid ${(p) => p.theme.colors.border};
  border-radius: ${(p) => p.theme.borderRadius};

  width: 28px;
  height: 28px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.colors.background};

  cursor: pointer;

  transition: filter ease-in 0.3s;

  * {
    cursor: pointer;
  }

  > svg {
    width: 12px;
    height: 12px;

    transition: color ease-in 0.3s;
  }

  &:hover {
    filter: brightness(0.97);

    > svg {
      color: ${({ theme }) => theme.colors.danger};
    }
  }
`;

export const QuantityWrapper = styled.div`
  height: 28px;
  border: 1px solid ${(p) => p.theme.colors.border};
  border-radius: ${(p) => p.theme.borderRadius};
  overflow: hidden;
  flex: 1;

  display: flex;
  align-items: center;

  > input {
    flex: 1;
    border: none;
    height: 28px;
    padding: 0 8px;
    font-size: ${(p) => p.theme.font.xSmall};

    &:disabled {
      background: ${({ theme }) => theme.colors.background};
    }
  }

  > button {
    border-left: 1px solid ${(p) => p.theme.colors.border};

    width: 28px;
    height: 28px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ theme }) => theme.colors.background};

    cursor: pointer;

    transition: filter ease-in 0.3s;

    * {
      cursor: pointer;
    }

    > svg {
      width: 12px;
      height: 12px;
      transition: color ease-in 0.3s;
    }

    &:hover {
      filter: brightness(0.97);

      > svg {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;
