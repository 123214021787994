import { FC } from 'react';

import { FiX } from 'react-icons/fi';

import { IBag } from '@pra-vendas-themes/interfaces/IBag';
import { Delivery } from '@pra-vendas-themes/default/components/Delivery';
import Button from '@pra-vendas-themes/default/components/Button';
import { Resume } from '@pra-vendas-themes/default/components/Bag/components/Resume';
import { Products } from '@pra-vendas-themes/default/components/Bag/components/Products';
import { EmptyBag } from '@pra-vendas-themes/default/components/Bag/components/EmptyBag';

import { maskPostalCode } from 'utils/masks';

import {
  Wrapper,
  Overlay,
  Drawer,
  Header,
  Title,
  ButtonClose,
  WrapperFooter,
  WrapperDelivery,
} from './styles';

const Bag: FC<IBag> = ({
  goToCart,
  goToCheckout,
  isHidden,
  isOpen,
  handleClose,
  cart,
  addOneItem,
  removeOneItem,
  onClickItem,
  onClickCalculateShipping,
  shippingOptions,
  selectShipping,
  shippingSelected,
  postalCode,
  discount,
  pickup,
  removeItem,
  changeItemQuantity,
}) => {
  if (!isOpen) return null;

  return (
    <Wrapper className={isHidden ? 'isHidden' : ''}>
      <Overlay onClick={handleClose} />
      <Drawer>
        <Header>
          <Title>SACOLA</Title>
          <ButtonClose onClick={handleClose}>
            <FiX />
          </ButtonClose>
        </Header>
        {!cart?.items?.length && <EmptyBag />}
        {(cart?.items?.length || 0) > 0 && (
          <>
            <Products
              onClickItem={onClickItem}
              items={cart?.items}
              addOneItem={addOneItem}
              removeOneItem={removeOneItem}
              removeItem={removeItem}
              changeItemQuantity={changeItemQuantity}
            />
            <WrapperDelivery>
              <Delivery
                postalCode={maskPostalCode(postalCode || '')}
                selectShipping={selectShipping}
                shippingSelected={shippingSelected}
                shippingOptions={shippingOptions}
                onClickCalculateShipping={onClickCalculateShipping}
                manufacturingTime={cart?.manufacturingTime}
                pickup={pickup}
              />
            </WrapperDelivery>
            <Resume
              shippingSelected={shippingSelected}
              total={cart?.total}
              discount={discount}
            />
            <WrapperFooter>
              <Button onClick={goToCart} transparent font="small">
                VER CARRINHO
              </Button>
              <Button onClick={goToCheckout} font="small">
                FINALIZAR COMPRA
              </Button>
            </WrapperFooter>
          </>
        )}
      </Drawer>
    </Wrapper>
  );
};

export default Bag;
