import { ChangeEventHandler, FC, useEffect, useRef, useState } from 'react';
import { FiMinus, FiPlus, FiTrash2 } from 'react-icons/fi';
import { IoImageOutline } from 'react-icons/io5';

import { ICartItem } from 'types/ICart';
import { IProduct } from 'types/IProduct';

import useDebounce from 'hooks/useDebounce';

import { formatCurrency } from 'utils/formatCurrency';

import {
  Image,
  Name,
  Price,
  ProductInfoWrapper,
  QuantityWrapper,
  RemoveProductButton,
  UnityPrice,
  WrapperAction,
  WrapperImage,
  WrapperPrice,
  WrapperProduct,
} from './styles';

interface IProductProps {
  item: ICartItem;
  addOneItem: (product: ICartItem | IProduct) => void;
  removeOneItem: (product: ICartItem | IProduct) => void;
  onClickItem: (product: IProduct) => void;
  removeItem: (product: ICartItem) => void;
  changeItemQuantity: (product: ICartItem, quantity: number) => void;
}

export const Product: FC<IProductProps> = ({
  item,
  addOneItem,
  onClickItem,
  removeItem,
  removeOneItem,
  changeItemQuantity,
}) => {
  const isFirstRender = useRef(true);
  const isChangingQuantity = useRef(false);

  const [quantity, setQuantity] = useState(0);

  const debouncedQuantity = useDebounce(quantity);

  const handleChangeQuantity: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    isChangingQuantity.current = true;

    setQuantity(Number(event.target.value));
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (!isChangingQuantity.current) return;

    changeItemQuantity(item, debouncedQuantity);

    isChangingQuantity.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuantity]);

  useEffect(() => {
    setQuantity(item.quantity);
  }, [item.quantity]);

  return (
    <WrapperProduct key={item._id}>
      <ProductInfoWrapper onClick={() => onClickItem(item.product as IProduct)}>
        <WrapperImage>
          {item?.product?.pictures?.[0] ? (
            <Image src={item?.product?.pictures?.[0]} />
          ) : (
            <IoImageOutline />
          )}
        </WrapperImage>

        <Name>
          <span>{item?.product?.relatedWith?.name || item?.product?.name}</span>

          {item.product?.relatedWith?.name && (
            <small>{item?.product?.name}</small>
          )}
        </Name>

        <WrapperPrice>
          <Price>{formatCurrency(quantity * (item.unitPrice || 0) || 0)}</Price>

          {quantity > 1 && (
            <UnityPrice>{formatCurrency(item.unitPrice || 0)} cada</UnityPrice>
          )}
        </WrapperPrice>
      </ProductInfoWrapper>

      <WrapperAction onClick={(e) => e.stopPropagation()}>
        <RemoveProductButton type="button" onClick={() => removeItem(item)}>
          <FiTrash2 />
        </RemoveProductButton>

        <QuantityWrapper>
          <input value={quantity} onChange={handleChangeQuantity} />

          <button type="button" onClick={() => removeOneItem(item)}>
            <FiMinus />
          </button>

          <button type="button" onClick={() => addOneItem(item)}>
            <FiPlus />
          </button>
        </QuantityWrapper>
      </WrapperAction>
    </WrapperProduct>
  );
};
