import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${(p) => p.theme.spacing.small};
  padding: ${(p) => p.theme.spacing.medium} ${(p) => p.theme.spacing.large};

  border-top: 1px solid ${(p) => p.theme.colors.border};
  font-size: ${(p) => p.theme.font.small};

  @media (max-width: 768px) {
    font-size: ${(p) => p.theme.font.xSmall};
    padding: ${(p) => p.theme.spacing.medium};
  }
`;

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-weight: bold;
  text-transform: uppercase;
`;

export const Value = styled.div``;
