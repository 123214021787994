import { FC } from 'react';

import { formatCurrency } from 'utils/formatCurrency';

import { IShippingOption } from 'types/IShipping';

import { Title, Value, Line, Wrapper } from './styles';

type TResume = {
  total?: number;
  shippingSelected?: IShippingOption;
  discount: number;
};

export const Resume: FC<TResume> = ({
  shippingSelected,
  total = 0,
  discount,
}) => {
  return (
    <Wrapper>
      <Line>
        <Title>subtotal</Title>
        <Value>{formatCurrency(total)}</Value>
      </Line>
      <Line>
        <Title>desconto</Title>
        <Value>
          {discount > 0 ? formatCurrency(discount) : 'Calculado no pagamento'}
        </Value>
      </Line>
      <Line>
        <Title>frete</Title>
        <Value>
          {shippingSelected ? formatCurrency(shippingSelected.price) : '-'}
        </Value>
      </Line>
      <Line>
        <Title>total</Title>
        <Value>
          {formatCurrency((total || 0) + (shippingSelected?.price || 0))}
        </Value>
      </Line>
    </Wrapper>
  );
};
